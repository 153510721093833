
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import {useStore} from "vuex";

interface FormCatalog {
  id?: number;
  name: string;
  code: string;
  billing_series_type_id: number;
  last_billing_number: number;
  prefix_billing_number: string;
  digits: number;
  sense: string;
  observations: string;
}

export default defineComponent({
  name: "BillingSeriesForm",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const form = ref<FormCatalog>({
      name: "",
      code: "",
      billing_series_type_id: 1,
      last_billing_number: 0,
      prefix_billing_number: "",
      digits: 0,
      sense: "",
      observations: "",
    });

    const options = ref([]) as any;

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const loadBillingSeriesTypes = () => {
      ApiService.get(
        "/api/billing_serie_types/lists?page=1&per_page=100" + props.entityId
      ).then(({ data }) => {
        options.value = data.billing_series_types;
      });
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      store.commit("setLoadingStatus", true);
      loadBillingSeriesTypes();
      if (props.entityId) {
        ApiService.get("/api/billing_serie/" + props.entityId).then(
          ({ data }) => {
            form.value = data;
            store.commit("setLoadingStatus", false);
          }
        );
      } else {
        store.commit("setLoadingStatus", false);
      }
    });

    const createElement = async (resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        const resp = (await ApiService.post("/api/billing_serie", form.value))
          .data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        store.commit("setLoadingStatus", false);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      ApiService.put("/api/billing_serie/" + props.entityId, form.value)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSubmit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      options,
      onSubmit,
      isNameRequired,
      updateElement,
      createElement,
    };
  },
});
